import { ajaxBusy } from "./ajax-busy";
import * as Enums from "./_enumerations";
import { CSSelect } from "./csSelect";
import { CSCheckbox } from "./csCheckbox";
declare const DataList: any;

export namespace DataListObj {
	interface ValidationMessage {
		Valuemissing: string;
	}

	export const LangCode: string = DataList.LangCode;
	export const CurrencyCode: string = DataList.CurrencyCode;
	export const PageType: string = DataList.PageType;
	export const MaxLineQuantity: number = DataList.MaxLineQuantity || 50;
	export const ValidationMessage: ValidationMessage = DataList.ValidationMessage;
	export const NostoPostRender: Promise<Event> = DataList.NostoPostRender;
	export const AdyenScriptUrl: string = DataList.AdyenScriptUrl;
	export const AdyenScriptIntegrity: string = DataList.AdyenScriptIntegrity;
	export const GoogleSigninScript: string = DataList.GoogleSigninScript;
	export const FacebookSigninScript: string = DataList.FacebookSigninScript;
	export const GoogleSigninClientId: string = DataList.GoogleSigninClientId;
	export const FacebookSigninAppId: string = DataList.FacebookSigninAppId;
	export const Debug: string = DataList.Debug;
	export const Empathy_Instance: string = DataList.Empathy_Instance;
	export const Empathy_LiveMode: boolean = (DataList.Empathy_LiveMode ?? "").toLowerCase() === "true";
	export const Empathy_Store: string = DataList.Empathy_Store;
	export const FlowboxKey: string = DataList.FlowboxKey;
	export const IsJavaScriptLoggingEnabled: boolean = DataList.IsJavaScriptLoggingEnabled;
	export const IsGA4Enabled: boolean = DataList.IsGA4Enabled;
	export const RM71259_Enabled: boolean = DataList.RM71259_Enabled;
	export const JIRA_CNF20_Enabled: boolean = DataList.JIRA_CNF20_Enabled;
	export const JIRA241_Enabled: boolean = DataList.JIRA241_Enabled;
}

export class ValidityState {
	badInput: boolean;
	customError: boolean;
	patternMismatch: boolean;
	rangeOverflow: boolean;
	rangeUnderflow: boolean;
	stepMismatch: boolean;
	tooLong: boolean;
	tooShort: boolean;
	typeMismatch: boolean;
	valid: boolean;
	valueMissing: boolean;
}

export enum ValidityStates {
	Invalid,
	Badinput,
	Customerror,
	Patternmismatch,
	Rangeoverflow,
	Rangeunderflow,
	Stepmismatch,
	Toolong,
	Tooshort,
	Typemismatch,
	Valid,
	Valuemissing
}

export enum DeliveryType {
	PostCode,
	ShopList
}

export enum DimensionType {
	Colours,
	Measures
}

export function clear(elem: NodeList | Element | Element[]) {
	let elems = [].concat(elem || []);

	for (let i = 0; i < elems.length; i++) {
		let elem = elems[i];
		while (elem.firstChild) {
			elem.removeChild(elem.firstChild);
		}
	}
}

export enum FetchMethod { GET, POST }
export interface FetchOptions {
	url: string;
	method?: FetchMethod;
	headers?: any;
	body?: any;
	showBusy?: boolean;
	busyContainer?: HTMLElement;
	busyTimeout?: number;
	success?: (e: Event) => void;
	always?: (e: Event) => void;
	allowRefresh?: boolean;
	error?: (e: Event) => void;
	retry?: number;
	followRedirects?: boolean;
}
export function Fetch(options: FetchOptions) {
	_Fetch(options);
}
(window as any).Fetch = Fetch;

function _Fetch(options: FetchOptions, retryAttempt = 1) {
	if (!options)
		return;

	// Defaults
	if (typeof options.showBusy !== "boolean")
		options.showBusy = true;
	if (typeof options.followRedirects !== "boolean")
		options.followRedirects = true;
	if (!options.method)
		options.method = FetchMethod.GET;

	const xhr = new XMLHttpRequest();
	xhr.onreadystatechange = (e) => Fetch_ReadyStateChange(e, options, retryAttempt);
	xhr.open(FetchMethod[options.method], options.url);

	xhr.setRequestHeader("x-requested-with", "XMLHttpRequest");
	if (options.headers) {
		for (const key in options.headers) {
			xhr.setRequestHeader(key, options.headers[key]);
		}
	}

	xhr.send(options.body);
}

function Fetch_ReadyStateChange(e: Event, options: FetchOptions, retryAttempt: number) {
	const xhr = e.target as XMLHttpRequest;

	if (xhr.readyState === 1) {
		if (options.showBusy && ajaxBusy)
			ajaxBusy.Show(options.busyContainer, options.busyTimeout);

		return;
	}

	if (xhr.readyState === 4) {
		if (xhr.status !== 200 && options.retry > 1 && retryAttempt < options.retry) {
			_Fetch(options, retryAttempt + 1);
			return;
		}

		if (options.showBusy && ajaxBusy)
			ajaxBusy.Hide(options.busyContainer);

		if (options.always)
			options.always(e);

		if (xhr.status === 200 && options.success) {
			if (options.followRedirects) {
				const location = xhr.getResponseHeader("location");
				if (location) {
					window.location.href = location;
					return;
				}
			}

			options.success(e);
		}

		if (xhr.status !== 200 && options.error)
			options.error(e);

		if (xhr.status === 205 && options.allowRefresh)
			location.reload();
	}
}

export function FormatThousandsSeparator(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const FormatPrice = (() => {
	if (!DataListObj.LangCode || !DataListObj.CurrencyCode)
		return (number: number) => number.toString();

	let formatter: Intl.NumberFormat;
	if (Intl) {
		formatter = new Intl.NumberFormat(DataListObj.LangCode, {
			style: "currency",
			currency: DataListObj.CurrencyCode
		});
	}

	return (number: number) => {
		if (formatter) {
			return formatter.format(number);
		}
		else {
			return number.toLocaleString(DataListObj.LangCode, {
				style: "currency",
				currency: DataListObj.CurrencyCode,
			});
		}
	};
})();

/** @description A faster way to replace an element's contents: https://ianopolous.github.io/javascript/innerHTML */
export function ReplaceInnerHTML(elemToReplace: Element, html: string, evalScript = false) {
	const newElem = elemToReplace.cloneNode(false) as HTMLElement;
	newElem.innerHTML = html;
	elemToReplace.parentNode.replaceChild(newElem, elemToReplace);

	if (evalScript)
		newElem.querySelectorAll("script").forEach((oldScript) => {
			if (oldScript.type && oldScript.type !== "text/javascript")
				return;

			const newScript = document.createElement("script");
			Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
			newScript.appendChild(document.createTextNode(oldScript.innerHTML));
			oldScript.parentNode.replaceChild(newScript, oldScript);
		});

	return newElem;
}

export function ReplaceOuterHTML(elemToReplace: Element, html: string, evalScript = false): HTMLElement {
	const parser = new DOMParser();
	const newElem = parser.parseFromString(html, "text/html").body.firstElementChild as HTMLElement;
	elemToReplace.parentNode.replaceChild(newElem, elemToReplace);

	if (evalScript)
		newElem.querySelectorAll("script").forEach((oldScript) => {
			if (oldScript.type && oldScript.type !== "text/javascript")
				return;

			const newScript = document.createElement("script");
			Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
			newScript.appendChild(document.createTextNode(oldScript.innerHTML));
			oldScript.parentNode.replaceChild(newScript, oldScript);
		});

	return newElem;
}

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function FormSubmit(path: string, params?: any, method = "post") {
	// The rest of this code assumes you are not using a library.
	// It can be made less wordy if you use one.
	const form = document.createElement("form");
	form.setAttribute("method", method);
	form.setAttribute("action", path);

	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			const hiddenField = document.createElement("input");
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("name", key);
			hiddenField.setAttribute("value", (params as any)[key]);

			form.appendChild(hiddenField);
		}
	}

	document.body.appendChild(form);
	form.submit();
}

export function Throttle(fn: () => void, wait: number, ...args: any[]) {
	let time = Date.now();
	return function () {
		if ((time + wait - Date.now()) < 0) {
			fn.apply(undefined, args); // eslint-disable-line prefer-spread
			time = Date.now();
		}
	};
}

export function NextSibling(elem: Element, selector: string) {

	// Get the next sibling element
	let sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling;
	}
}

export function PreviousSibling(elem: Element, selector: string) {

	// Get the previous sibling element
	let sibling = elem.previousElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the previous sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.previousElementSibling;
	}
}

export function GetViewportWidth() {
	return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function GetViewportHeight() {
	return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

export function LoadScript(url: string, onLoad?: () => any) {
	const script = document.createElement("script");
	if (onLoad)
		script.onload = onLoad;
	document.head.appendChild(script);
	script.src = url;
}

export function isScrolledIntoView(el: HTMLElement) {
	let rect = el.getBoundingClientRect();
	let elemTop = rect.top;
	let elemBottom = rect.bottom;

	// Only completely visible elements return true:
	let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	//isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}

// This only works if el is not position:fixed
export function IsHidden(el: HTMLElement) {
	return !el || el.offsetParent === null;
}

export function AjaxBusyShow() {
	if (!ajaxBusy)
		return false;

	ajaxBusy.Show(undefined, 0);
	return true;
}

export function AjaxBusyHide() {
	if (!ajaxBusy)
		return false;

	ajaxBusy.Hide();
	return true;
}

export function ChangeExtension(filePath: string, newExtension: Enums.Extension) {
	const pos = filePath.lastIndexOf(".");
	return filePath.substr(0, pos < 0 ? filePath.length : pos) + "." + Enums.Extension[newExtension].toLowerCase();
}

export function EscapeRegExp(text: string) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function CheckValidity(field: HTMLInputElement | CSSelect, ignoreValueMissing = false) {
	if (!field)
		return 1;

	const container = field.closest("label");
	if (container?.hasAttribute("data-error-dismissed"))
		return 1;

	const valid = field.checkValidity();

	if (valid ||
		(field.validity.valueMissing && ignoreValueMissing) ||
		IsHidden(field)) {
		SetValid(container);
		return +valid;
	}

	let state, message;
	if (field.validity.tooShort)
		state = ValidityStates.Tooshort;
	else if (field.validity.patternMismatch)
		state = ValidityStates.Patternmismatch;
	else if (field.validity.valueMissing)
		state = ValidityStates.Valuemissing;
	else if (field.validity.customError) {
		state = ValidityStates.Customerror;
		message = field.validationMessage;
	}

	SetInvalid(container, state, message);
	return 0;
}

export function CheckValidityNextSibling(field: HTMLInputElement | CSSelect, ignoreValueMissing = false) {
	if (!field)
		return 1;

	const container = field.nextElementSibling;
	if (container.hasAttribute("data-error-dismissed"))
		return 1;

	const valid = field.checkValidity();

	if (valid ||
		(field.validity.valueMissing && ignoreValueMissing) ||
		IsHidden(field)) {
		SetValid(container.parentElement);
		return +valid;
	}

	let state, message;
	if (field.validity.tooShort)
		state = ValidityStates.Tooshort;
	else if (field.validity.patternMismatch)
		state = ValidityStates.Patternmismatch;
	else if (field.validity.valueMissing)
		state = ValidityStates.Valuemissing;
	else if (field.validity.customError) {
		state = ValidityStates.Customerror;
		message = field.validationMessage;
	}

	SetInvalidHtml(container.parentElement, state, message);
	return 0;
}

export function SetValid(container: HTMLElement) {
	container?.classList.remove("error");
}

export function SetInvalid(container: HTMLElement, state: ValidityStates = ValidityStates.Invalid, message?: string) {
	if (!container || container.hasAttribute("data-error-dismissed"))
		return;

	container.classList.add("error");

	if (!message && state == ValidityStates.Valuemissing)
		message = DataListObj.ValidationMessage.Valuemissing;

	if (!message)
		message = container.dataset[`validity${ValidityStates[state]}`];

	if (!message)
		message = container.dataset[`validity${ValidityStates[ValidityStates.Invalid]}`];

	if (message) {
		const validation = container.querySelector(".validation");
		if (validation) {
			const text = validation.querySelector(".text");
			(text ? text : validation).textContent = message;

			const dismiss = validation.querySelector(".dismiss") as HTMLElement;
			if (dismiss && !dismiss.onclick)
				dismiss.onclick = (e: Event) => {
					e.preventDefault();

					container.setAttribute("data-error-dismissed", "");
					container.classList.remove("error");
				};
		}
	}
}

export function SetInvalidHtml(container: HTMLElement, state: ValidityStates = ValidityStates.Invalid, message?: string) {
	if (container.hasAttribute("data-error-dismissed"))
		return;

	container.classList.add("error");

	if (!message && state == ValidityStates.Valuemissing)
		message = DataListObj.ValidationMessage.Valuemissing;

	if (!message)
		message = container.dataset[`validity${ValidityStates[state]}`];

	if (!message)
		message = container.dataset[`validity${ValidityStates[ValidityStates.Invalid]}`];

	if (message) {
		const validation = container.querySelector(".validation");
		if (validation) {
			const text = validation.querySelector(".text");
			(text ? text : validation).innerHTML = message;

			const dismiss = validation.querySelector(".dismiss") as HTMLElement;
			if (dismiss && !dismiss.onclick)
				dismiss.onclick = (e: Event) => {
					e.preventDefault();

					container.setAttribute("data-error-dismissed", "");
					container.classList.remove("error");
				};
		}
	}
}

export function GetUrlParts(url: string) {
	const a = document.createElement("a");
	a.href = url;

	return {
		href: a.href,
		host: a.host,
		hostname: a.hostname,
		port: a.port,
		pathname: a.pathname,
		protocol: a.protocol,
		hash: a.hash,
		search: a.search
	};
}

export function IsPrintableKey(keyCode: number, includeReturnKeys = false, includeDeleteKeys = false) {
	return (keyCode > 47 && keyCode < 58) || // number keys
		keyCode == 32 || (keyCode == 13 && includeReturnKeys) || // spacebar & return key(s) (if you want to allow carriage returns)
		(keyCode > 64 && keyCode < 91) || // letter keys
		(keyCode > 95 && keyCode < 112) || // numpad keys
		(keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
		(keyCode > 218 && keyCode < 223) || // [\]' (in order)
		((keyCode == 8 || keyCode == 46) && includeDeleteKeys); // backspace & delete key(s)
}

export function IsElementInViewport(el: Element, excludeBorder = false) {
	if (!el)
		return;

	const rect = el.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= ((!excludeBorder && window.innerHeight) || document.documentElement.clientHeight) && /*or $(window).height() */
		rect.right <= ((!excludeBorder && window.innerWidth) || document.documentElement.clientWidth) /*or $(window).width() */
	);
}

export function GetParameterByName(name: string, url = window.location.href) {
	name = name.replace(/[[]]/g, "\\$&");
	const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return undefined;
	if (!results[2]) return "";
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export function SetDraggable(elem: HTMLElement, handle = elem, container: Element | Document = document) {
	let active = false;
	let currentX: number;
	let currentY: number;
	let initialX: number;
	let initialY: number;
	let xOffset = 0;
	let yOffset = 0;

	function dragStart(e: PointerEvent) {
		if (e.target !== handle)
			return;

		initialX = e.clientX - xOffset;
		initialY = e.clientY - yOffset;

		active = true;
		handle.classList.add("drag");
	}

	function dragEnd(_e: PointerEvent) {
		initialX = currentX;
		initialY = currentY;

		active = false;
		handle.classList.remove("drag");
	}

	function drag(e: PointerEvent) {
		if (active) {

			e.preventDefault();

			currentX = e.clientX - initialX;
			currentY = e.clientY - initialY;

			xOffset = currentX;
			yOffset = currentY;

			setTranslate(currentX, currentY, elem);
		}
	}

	function setTranslate(xPos: number, yPos: number, el: HTMLElement) {
		el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
	}

	const controller = new AbortController();
	container.addEventListener("pointerdown", dragStart, { capture: false, signal: controller.signal } as any);
	container.addEventListener("pointerup", dragEnd, { capture: false, signal: controller.signal } as any);
	container.addEventListener("pointermove", drag, { capture: false, signal: controller.signal } as any);

	return controller;
}

export function ParseOADate(oaDate: number) {
	const date = new Date();
	date.setTime((oaDate - 25569) * 24 * 3600 * 1000);
	return date;
}

export function FormatDate(date: Date) {
	return date.toLocaleDateString(DataListObj.LangCode);
}

export function FormatDateTime(date: Date) {
	return date.toLocaleString(DataListObj.LangCode);
}

export function ToggleEventListener(node: Node, type: string, listener: EventListenerOrEventListenerObject, toggle: boolean) {
	if (toggle)
		node.addEventListener(type, listener);
	else
		node.removeEventListener(type, listener);
}

// BEGIN: Imported from cs.base.js. To refactor:

export function updateQueryStringParameter(uri: any, key: any, value: any) {
	if (!value)
		return removeQueryStringParameter(uri, key);

	let queryString = "";
	let newUrl = "";
	if (uri.indexOf("?") != -1) {
		newUrl = uri.substring(0, uri.indexOf("?"));
		queryString = uri.substring(uri.indexOf("?") + 1, uri.length);
	}
	else {
		newUrl = uri;
		queryString = "";
	}

	let found = false;
	let urlQueryStrings = queryString.split(/[?#&]+/);

	let currentSeparator = "?";
	$(urlQueryStrings).each(function (_eindex: any, evalue: any) {
		{
			let keyvalue = evalue.split("=");
			if (keyvalue.length > 1) {
				let qrykey = keyvalue[0];
				let qryvalue = keyvalue[1];

				if (qrykey.toLowerCase() == key.toLowerCase()) {
					qryvalue = value;
					found = true;
				}

				newUrl += currentSeparator + qrykey + "=" + qryvalue;
				currentSeparator = "&";
			}
		}
	});
	if (!found) {
		newUrl += currentSeparator + key + "=" + value;
	}
	//console.log("New url after update " + key + " : " + newUrl);
	return newUrl;
}

export function removeQueryStringParameter(uri: any, key: any) {
	let newUrl = "";

	if (uri !== undefined) {
		let queryString = "";
		if (uri.indexOf("?") != -1) {
			newUrl = uri.substring(0, uri.indexOf("?"));
			queryString = uri.substring(uri.indexOf("?") + 1, uri.length);
		}
		else {
			newUrl = uri;
			queryString = "";
		}

		let found = false;
		let urlQueryStrings = queryString.split(/[?#&]+/);

		let currentSeparator = "?";
		$(urlQueryStrings).each(function (_eindex: any, evalue: any) {
			{
				let keyvalue = evalue.split("=");
				if (keyvalue.length > 1) {
					let qrykey = keyvalue[0];
					let qryvalue = keyvalue[1];

					if (qrykey.toLowerCase() == key.toLowerCase()) {
						found = true;
					}
					else {
						newUrl += currentSeparator + qrykey + "=" + qryvalue;
						currentSeparator = "&";
					}
				}
			}
		});
		//console.log("New url after delete " + key + " : " + newUrl);
	}
	return newUrl;
}

export function readCookie(name: any) {
	let nameEQ = encodeURIComponent(name) + "=";
	let ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
	}
	return null;
}

export function createCookie(name: any, value: any, days: any) {
	let expires;

	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + (date as any).toGMTString();
	} else {
		expires = "";
	}
	document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

// END: Imported from cs.base.js. To refactor:

export function LogExceptionToAPI(errorMessage: string, stackTrace: string) {
	let exception: {
		ErrorMessage: string;
		StackTrace: string;
		ErrorDate: string;
	} = {
		ErrorMessage: errorMessage,
		StackTrace: stackTrace,
		ErrorDate: new Date().toJSON()
	};

	return new Promise<XMLHttpRequest>((resolve, reject) => {
		Fetch({
			url: "/slapi/admin/logJavaScriptException",
			method: FetchMethod.POST,
			success: (e) => resolve(e.target as XMLHttpRequest),
			error: (e) => reject(e),
			headers: { "Content-Type": "application/json;charset=UTF-8" },
			body: JSON.stringify(exception),
			showBusy: false
		});
	});
}